'use strict';

var util = require('./util');

const CART_PAGE = 'Cart-Show';
const LOGIN_PAGE = 'Login-Show';

// eslint-disable-next-line consistent-return, require-jsdoc
function newsletterPopIn() {
    var didomiLocalStorage = window.localStorage.getItem('euconsent-v2');
    var redirectPopInClosed = window.localStorage.getItem('redirectPopInClosed');
    const pageDataAction = $('.page').data('action');
    const disablePageActions = [CART_PAGE, LOGIN_PAGE];

    if (!didomiLocalStorage || redirectPopInClosed === 'false' || disablePageActions.includes(pageDataAction)) {
        return true;
    }

    sessionStorage.clicks = sessionStorage.clicks || 0;

    if (parseInt(sessionStorage.clicks, 10) < 4) {
        sessionStorage.clicks++;
    }

    if (sessionStorage.clicks === '3') {
        var url = $('.js-newsletter').data('url');
        if (url) {
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'JSON'
            }).always(function (data) {
                if (data.responseText === 'error') {
                    return;
                }
                $('body').prepend(data.responseText);
                var $newsletterPopin = $('#newsletterModal');
                $newsletterPopin.modal('show');

                util.focusModal($newsletterPopin);

                setTimeout(function () {
                    $('.modal-backdrop').remove();
                }, 300);

                $('#newsletterForm').on('submit', function () {
                    var date = new Date();
                    date.setTime(date + (60 * 60 * 1000));
                    document.cookie = 'isSubscribed=true; expires=' + date.toGMTString() + '; path=/';
                });
            });
        }
    }
}

$(document).ready(function () {
    newsletterPopIn();
});
